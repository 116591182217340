import type ApolloClient from 'apollo-client';
import type { ExtensionProvider, ExtensionManifest } from '@atlaskit/editor-common/extensions';
import type { EditorActions } from '@atlaskit/editor-core';
import { JSONTransformer } from '@atlaskit/editor-json-transformer';
import { fg } from '@atlaskit/platform-feature-flags';
import { parse } from '@atlassian/cs-ari';
import {
	type AnalyticsWebClient,
	type ForgeUIAnalyticsContext,
} from '@atlassian/forge-ui/analytics';

import {
	type GQLExtensionContextsFilter,
	getForgeUIExtensionsAsync,
} from '@atlassian/forge-ui/provider';

import {
	type ContextId,
	type ProductEnvironment,
	type ForgeUIExtensionType,
	type EnvironmentType,
	ExtensionEnvironment,
} from '@atlassian/forge-ui-types';
import { type ExtensionHandlerWithReferenceFn } from '@atlassian/editor-referentiality';
import { type ProviderFactory } from '@atlaskit/editor-common/provider-factory';

import {
	CONFIG_USER_PICKER_PROVIDER,
	validateForgeConfigPayload,
	renderConfigHelper,
	transformConfigBefore,
	transformConfigAfter,
	createUIKitOneGetFieldsDefinitionFunction,
} from '../config';
import {
	renderExtension,
	renderBlockedMacroExtension,
	shouldBlockExtensionDueToAppAccessPolicy,
} from '../render';
import {
	type CustomEditContext,
	type ForgeExtension,
	type ForgeExtensionManifest,
	type ForgeExtensionParameters,
	type UpdateFunction,
} from '../types';
import { maybeUpgradeConnectExtensionToForge } from './upgradeConnectNodeToForge';
import { AnalyticsAction, createAnalyticsClient } from '../utils/analytics';
import { EXTENSION_NAMESPACE, MODULE_NAME } from '../utils/constants';
import type { FlagFunctions, FlagProviderHOC } from '../utils/getFlagProvider';

import {
	getExtensionManifestMapper,
	manifestIcons,
	updateNodeWithPayload,
} from './convertToExtensionManifest';
import { ForgeExtensionProvider } from './forgeExtensionProvider';
import { createAutoConvertDefinitions } from './macro-auto-convert/createAutoConvertDefinitions';
import { createForgeMacroAutoConverter } from './macro-auto-convert/createForgeMacroAutoConverter';
import { createAutoConvertMatcherToExtensionsMap } from './macro-auto-convert/createAutoConvertMatcherToExtensionsMap';

export interface ForgeExtensionProviderParams {
	accountId?: string;
	cloudId: string;
	apolloClient: ApolloClient<object>;
	contextIds: ContextId[];
	dataProviders: ProviderFactory;
	environment: ProductEnvironment;
	product: string;
	page: string;
	isEditing?: boolean;
	analyticsWebClient: AnalyticsWebClient | Promise<AnalyticsWebClient>;
	forgeUIAnalyticsContext: ForgeUIAnalyticsContext;
	extensionData: Record<string, any>;
	FlagProvider?: FlagProviderHOC;
	extensionHandlerWithReference?: ExtensionHandlerWithReferenceFn<ForgeExtensionParameters>;
	connectExtensionProvider?: Promise<ExtensionProvider>;
	editorActions?: EditorActions;
	extensionsFilter?: GQLExtensionContextsFilter[];
	dataClassificationTagIds?: string[];
	hideGlassPane?: boolean;
	locale?: string;
}

const humanReadableEnvironmentType: Record<EnvironmentType, string> = {
	DEVELOPMENT: 'Development',
	STAGING: 'Staging',
	PRODUCTION: 'Production',
};

export const createTitleWithEnvironmentInfo = (
	title: string,
	environmentType: EnvironmentType = ExtensionEnvironment.PRODUCTION,
	environmentKey?: string,
): string => {
	if (environmentType === ExtensionEnvironment.PRODUCTION) {
		return title;
	}

	if (environmentKey === undefined) {
		return `${title} (${humanReadableEnvironmentType[environmentType]})`;
	}

	if (environmentType === ExtensionEnvironment.DEVELOPMENT && environmentKey !== 'default') {
		return `${title} - ${environmentKey} (Dev)`;
	}

	return `${title} (${humanReadableEnvironmentType[environmentType]})`;
};

/*
	This exists to provide a default value for the flag functions that Confluence
	is meant to inject with the FlagProvider param. The param must be optional in
	getForgeExtensionProvider because breaking changes are disallowed without a
	deprecation period (which we want to avoid) - see go/releasing-changes-safely.
	The renderExtension function expects FlagProvider to be defined, so this default is provided.
*/
const noopFlags: FlagFunctions = {
	showFlag: async () => {
		return {};
	},
	hideFlag: async () => {},
};
const noopFlagProvider: FlagProviderHOC = ({
	children,
}: {
	children: (flags: FlagFunctions) => JSX.Element;
}) => children(noopFlags);

// We keep track of the reason that an extension failed to upgrade, for analytics purposes
const connectToForgeBlockedExtensions = new Set();

export const getForgeExtensionProvider = async ({
	accountId,
	cloudId,
	apolloClient,
	contextIds,
	dataProviders,
	environment,
	product,
	page,
	isEditing,
	analyticsWebClient,
	forgeUIAnalyticsContext = {},
	extensionData,
	extensionHandlerWithReference,
	connectExtensionProvider,
	editorActions,
	FlagProvider = noopFlagProvider,
	extensionsFilter,
	dataClassificationTagIds,
	hideGlassPane,
	locale,
}: ForgeExtensionProviderParams): Promise<ExtensionProvider> => {
	let extensionProvider: ForgeExtensionProvider;

	const createRenderFunction = async (
		extension?: ForgeUIExtensionType,
		customEditContext?: CustomEditContext,
	) =>
		extension && shouldBlockExtensionDueToAppAccessPolicy(extension)
			? renderBlockedMacroExtension({ extension })
			: renderExtension({
					accountId,
					analyticsWebClient,
					cloudId,
					extension,
					extensionData,
					extensionHandlerWithReference,
					forgeUIAnalyticsContext,
					apolloClient,
					contextIds,
					isEditing: Boolean(isEditing),
					dataProviders,
					environment,
					product,
					page,
					FlagProvider,
					hideGlassPane,
					customEditContext,
				});

	const getFieldsDefinitionFunction = createUIKitOneGetFieldsDefinitionFunction({
		analyticsWebClient,
		apolloClient,
		contextIds,
		cloudId,
		extensionData,
	});

	const { extensions } = await getForgeUIExtensionsAsync({
		client: apolloClient,
		contextIds,
		moduleType: MODULE_NAME,
		expandAppOwner: true,
		extensionsFilter,
		dataClassificationTagIds,
		locale,
	});
	const eventSource = editorActions ? 'editPageScreen' : 'viewPageScreen';

	const analyticsClient = createAnalyticsClient({
		analyticsWebClient,
		forgeUIAnalyticsContext,
		source: eventSource,
	});
	const { sendEvent } = analyticsClient;

	const updateFunction: UpdateFunction = async (
		parameters,
		actions,
		extension,
		hasCustomConfig,
	) => {
		await maybeUpgradeConnectExtensionToForge({
			actions,
			analyticsClient,
			connectToForgeBlockedExtensions,
			editorActions,
			extensionProvider,
		});

		const node = editorActions?.getSelectedNode();

		const isBodiedExtension = node?.type.name === 'bodiedExtension';
		if (node && extension && actions && (hasCustomConfig || isBodiedExtension)) {
			/**
			 * Converts a ProseMirror Node (received by EditorActions API) to a ForgeExtension (provided to render function)
			 * This includes:
			 * - Extracting the node's attributes
			 * - Extracting the node's type
			 * - Transforming the node's content from ProseMirror to ADF JSON (and handling any errors)
			 */
			const forgeExtensionNode = (() => {
				const forgeExtension = {
					...node.attrs,
					type: node.type.name,
				} as ForgeExtension;

				try {
					const { content } = new JSONTransformer().encode(node);
					return { ...forgeExtension, content };
				} catch (error: unknown) {
					// failed to encode node to JSON
					// continue rendering custom config without content and emit analytics error event
					const { localId } = node?.attrs;
					sendEvent(AnalyticsAction.FORGE_EXTENSION_FAILED, {
						extension,
						error,
						localId,
					});
					return forgeExtension;
				}
			})();

			// No need to wait for config to render to start showing the panel (for non-custom config)
			// noinspection ES6MissingAwait
			renderConfigHelper({
				extension,
				forgeExtensionNode,
				hasCustomConfig: Boolean(hasCustomConfig),
				isInitialInsertion: false,
				createRenderFunction,
				onSubmit: (payload) => updateNodeWithPayload(actions, node.attrs.localId, payload),
				onValidate: (data) =>
					validateForgeConfigPayload(data, {
						isBodiedExtension,
						isInitialInsertion: false,
						schema: editorActions?._privateGetEditorView?.()?.state.schema,
						useStrictParameterValidation: fg('enable-strict-forge-macro-parameter-validation-new'),
					}),
			});
		}

		if (!hasCustomConfig) {
			return actions!.editInContextPanel(transformConfigBefore, async (params) =>
				transformConfigAfter(params),
			);
		}
	};

	const fieldsModules: ExtensionManifest<ForgeExtensionParameters>['modules']['fields'] = {
		user: {
			[CONFIG_USER_PICKER_PROVIDER]: {
				provider: async () => ({
					siteId: cloudId,
					// If principalId === 'Context', upstream attempts to use other context to discover the principal ID
					principalId: accountId || 'Context',
					fieldId: 'forgeConfigUserPicker',
					productKey: product as 'confluence',
				}),
			},
		},
	};

	const { autoConvertDefinitions, macroADFNodeMap } = createAutoConvertDefinitions(extensions);

	const autoConvertMatcherToExtensionsMap = createAutoConvertMatcherToExtensionsMap(
		autoConvertDefinitions,
		macroADFNodeMap,
	);

	const convertLinkToADFNode = createForgeMacroAutoConverter(
		analyticsClient,
		autoConvertDefinitions,
		macroADFNodeMap,
		autoConvertMatcherToExtensionsMap,
	);

	const convertToExtensionManifest = getExtensionManifestMapper({
		analyticsClient,
		editorActions,
		eventSource,
		fieldsModules,
		renderConfigHelper,
		createRenderFunction,
		connectToForgeBlockedExtensions,
		getFieldsDefinitionFunction,
		updateFunction,
		autoConvertDefinitions,
		autoConvertMatcherToExtensionsMap,
	});

	const extensionManifests: ForgeExtensionManifest[] = (extensions || [])
		.filter(({ id }) => parse(id).resourceId !== undefined)
		.map(convertToExtensionManifest);

	const legacyMacroManifest: ExtensionManifest<ForgeExtensionParameters> = {
		/**
		 * title, description and icons are required but will not be used anywhere,
		 * since this manifest is used solely for rendering legacy nodes
		 **/
		title: 'Forge UI Macro',
		description: 'Supports existing Forge UI Macro nodes',
		type: EXTENSION_NAMESPACE,
		key: 'xen:macro',
		icons: manifestIcons,
		modules: {
			nodes: {
				default: {
					type: 'extension',
					render: createRenderFunction,
					update: updateFunction,
					getFieldsDefinition: getFieldsDefinitionFunction,
				},
			},
			fields: fieldsModules,
		},
	};

	const manifests = [legacyMacroManifest, ...extensionManifests];

	const isAutoConvertEnabled = fg('forge-ui-macro-autoconvert');

	extensionProvider = new ForgeExtensionProvider(
		manifests,
		connectExtensionProvider,
		undefined,
		isAutoConvertEnabled
			? [
					(link: string) => {
						const result = convertLinkToADFNode(link);
						if (result) {
							return result;
						}
						return undefined;
					},
				]
			: undefined,
		analyticsClient,
	);
	return extensionProvider;
};
