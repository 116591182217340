import React from 'react';
import { defineMessages, type IntlShape } from 'react-intl-next';

import type { EditorActions } from '@atlaskit/editor-core';
import { DefaultExtensionProvider } from '@atlaskit/editor-common/extensions';
import { token } from '@atlaskit/tokens';

import { LoadableAfterPaint } from '@confluence/loadable';

import {
	hubElementPlaceholderExtensionKey,
	hubElementPlaceholderExtensionType,
} from './hubElementPlaceholderConstants';
import type { HubElementPlaceholderExtensionProps } from './HubElementPlaceholderExtension';

const HubElementPlaceholderExtension = LoadableAfterPaint({
	loader: async () =>
		(
			await import(
				/* webpackChunkName: "loadable-HubElementPlaceholderExtension" */ './HubElementPlaceholderExtension'
			)
		).HubElementPlaceholderExtension,
});

const i18n = defineMessages({
	title: {
		id: 'custom-sites-extensions.hubElementPlaceholder.manifest.extension-title.non-final',
		defaultMessage: 'Hub element placeholder',
		description:
			'Title of hub element placeholder extension to be displayed the macro is presented in the editor.',
	},
});

type HubElementPlaceholderManifestArguments = {
	editorActions?: EditorActions;
	openConfigPanel?: (localId: string) => void;
	intl: IntlShape;
} & Pick<HubElementPlaceholderExtensionProps, 'contentId' | 'createAnalyticsEvent'>;

export const hubElementPlaceholderExtensionProvider = async ({
	editorActions,
	openConfigPanel,
	intl,
	...extensionPassThroughProps
}: HubElementPlaceholderManifestArguments) => {
	const type = hubElementPlaceholderExtensionType;
	const title = intl.formatMessage(i18n.title);

	const icons = {
		'48': () =>
			import(
				/* webpackChunkName: "loadable-atlaskitIconCorePremium" */ '@atlaskit/icon/core/premium'
			).then(({ default: PremiumIcon }) => () => (
				<PremiumIcon color={token('color.icon.subtle')} label="" spacing="spacious" />
			)),
	};

	const update = async () => {
		const selectedNode = editorActions?.getSelectedNode()?.toJSON();
		const selectedLocalId = selectedNode?.attrs?.localId;

		if (!!selectedLocalId && openConfigPanel) {
			openConfigPanel(selectedLocalId);
		}
	};

	const render =
		async () =>
		({ node }) =>
			// Only render the extension if we are in the editor
			editorActions ? (
				<HubElementPlaceholderExtension
					extensionNode={node}
					editorActions={editorActions}
					openConfigPanel={openConfigPanel}
					{...extensionPassThroughProps}
				/>
			) : null;

	return new DefaultExtensionProvider([
		{
			key: hubElementPlaceholderExtensionKey,
			icons,
			title,
			type,
			description: '',
			modules: {
				nodes: {
					default: {
						type: 'extension',
						render,
						update,
					},
				},
			},
		},
	]);
};
